<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <b-modal
        id="import-file-modal"
        :visible="isImportFileActive"
        size="lg"
        centered
        hide-footer
        @hidden="resetForm"
        @hide="resetForm"
        @change="(val) => $emit('update:is-import-file-active', val)"
      >
        <template #modal-header>
          <div class="d-flex justify-content-end px-2 mt-2 w-100">
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="20"
              @click="() => $emit('update:is-import-file-active', false)"
            />
          </div>
        </template>

        <b-overlay :show="loading" opacity="0.5">
          <b-form-file
            v-show="false"
            ref="import_file"
            accept="text/csv"
            placeholder=""
            @input="onFileSelected"
          ></b-form-file>

          <div
            class="text-center p-3 mx-2"
            style="border-style: dashed; border-width: 2px;"
            @drop="onDrop($event)"
            @dragover.prevent
            @dragenter.prevent
          >
            <div v-if="!selectedFile">
              <feather-icon
                class="mb-1 cursor-pointer text-primary"
                icon="UploadCloudIcon"
                size="80"
              />
              <h1 class="text-dark">
                Drag and drop file here
              </h1>
              <h2 class="text-muted">
                or
              </h2>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                @click="onBrowseFiles"
              >
                <span class="align-middle">Browse Files</span>
              </b-button>
              <h5 class="mt-2">
                Supported file formats - CSV
              </h5>
            </div>
            <div v-else>
              <h4>File Selected:</h4>
              <div class="my-2">
                {{ selectedFile.name }}
              </div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                @click="resetForm"
              >
                <span class="align-middle">Change file</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="() => $emit('onImport', selectedFile)"
              >
                <span class="align-middle">Proceed</span>
              </b-button>
            </div>
          </div>
          <h5 class="mt-2 text-dark text-center">
            Click <span class="text-primary cursor-pointer" @click="downloadSampleFile">here</span> to get a sample file
          </h5>
        </b-overlay>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FileDownload from "js-file-download";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { get } from "lodash"
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";


export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    BFormFile,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isImportFileActive: {
      type: Boolean,
      required: false
    },
    importType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      required,
      selectedFile: null
    };
  },
  methods: {
    onBrowseFiles() {
      const import_file_ref = this.$refs.import_file
      import_file_ref.$el.childNodes[0].click();
    },
    onDrop(e) {
      e.preventDefault()
      const files = e.dataTransfer?.files;
      const [file] = files;
      this.selectedFile = file;
    },
    onFileSelected(file) {
      this.selectedFile = file;
    },
    resetForm() {
      this.selectedFile = null;
    },
    async downloadSampleFile() {
      try {
        this.loading = true;

        const response = await this.useJwt().adminService.downloadSampleCSVFile(this.importType);
        FileDownload(response.data, `${this.importType}_sample.csv`);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "Sample file downloaded successfully."
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style lang="scss">

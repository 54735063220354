<template>
  <div>
    <b-row class="mb-2">
      <b-col v-if="showSearch"
        cols="12"
        :md="hasActionButton ? 7 : 8"
        :sm="hasActionButton ? 7 : 8"
        class="d-flex align-items-center justify-content-start"
      >
        <search-with-auto-complete-vue
          v-if="useAutoCompleteSearch"
          :search-type="searchType"
          @selected="(val) => $emit('update:searchFilter', val)"
        />
        <b-input-group v-else class="input-group-merge">
          <b-form-input
            :value="searchFilter"
            placeholder="Search"
            class="search-product"
            @input="$event => $emit('update:searchFilter', $event)"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col v-else-if="showFilter && showFilter" 
        cols="12"
        :md="hasActionButton ? 7 : 8"
        :sm="hasActionButton ? 7 : 8"
      >
        <b-card>
          <slot name="filters"></slot>
        </b-card>
      </b-col>


      <b-col 
        cols="12"
        :md="hasActionButton ? 5 : 4"
        :sm="hasActionButton ? 5 : 4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          v-if="hasFilter"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :variant="showFilter ? 'primary' : 'outline-primary'"
          class="mr-1"
          @click="onToggleFilter()"
        >
          <feather-icon
            icon="FilterIcon"
            class="mr-50"
          />
          <span class="align-middle text-lg">Filter</span>
        </b-button>
          
        <b-button
          v-if="showExportButton"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mr-1"
          @click="$emit('export')"
        >
          <feather-icon
            icon="LogOutIcon"
            class="mr-50"
          />
          <span class="align-middle">Export</span>
        </b-button>

        <b-button
          v-if="showImportButton"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="mr-1"
          @click="onTriggerSelectImportLoanRecordsCsvFile"
        >
          <feather-icon
            icon="DownloadCloudIcon"
            class="mr-50"
          />
          <b-form-file
            v-show="false"
            ref="loan_repayment_file"
            accept="text/csv"
            placeholder=""
            no-drop
          />
          <span class="align-middle">Import</span>
        </b-button>

        <slot name="action_button"></slot>
      </b-col>
    </b-row>

    <div v-if="showFilter && showFilter && showSearch" class="mb-2">
      <b-card>
        <slot name="filters"></slot>
      </b-card>
    </div>

    <div v-if="hasLegend" class="mb-2">
      <slot name="legend"></slot>
    </div>

    <div>
      <b-card no-body>
        <b-table
          ref="listTable"
          show-empty
          responsive
          primary-key="id"
          class="position-relative"
          empty-text="No records found"
          sticky-header
          :no-border-collapse="false"
          :table-class="tableClass"
          :select-mode="selectionMode"
          :selectable="['multi', 'range'].includes(selectionMode)"
          :hover="true"
          :items="filteredRecords"
          :fields="columns"
          :tbody-tr-class="tbodyRowClass"
          @row-clicked="onTbRowClicked"
          @row-selected="onRowsSelected"
        >
          <template v-if="['multi', 'range'].includes(selectionMode)" #head(check)>
            <b-form-checkbox :disabled="!records.length" @change="onCheckOrUncheckAllRecords" />
          </template>

          <template v-if="['multi', 'range'].includes(selectionMode)" #cell(check)="{ index, rowSelected }">
            <b-form-checkbox :checked="rowSelected" @change="onCheckOrUncheckRow($event, index)" />
          </template>

          <template 
            v-for="slotName in Object.keys($scopedSlots)" 
            v-slot:[slotName]="scopeSlotData"
          >
            <slot :name="slotName" v-bind="scopeSlotData"></slot>
          </template>
        </b-table>
        
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ pageMeta.from }} to {{ pageMeta.to }} of {{ pageMeta.of }} entries</span>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>

    <import-file-modal
      :is-import-file-active.sync="isImportFileActive"
      :import-type="importType"
      @onImport="onImportUserRecordsCSVFileSelected"
    />
  </div>
</template>

<script>
import { get } from "lodash"
import {
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BInputGroup, BInputGroupAppend, BFormInput, BFormCheckbox
} from 'bootstrap-vue'


import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ImportFileModal from '@core/components/import-file-modal/index.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import SearchWithAutoCompleteVue from '@core/components/shared/search/SearchWithAutoComplete.vue';

export default {
  components: {
    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BInputGroup, 
    BFormInput,
    BFormCheckbox,
    BInputGroupAppend, 
    ImportFileModal,
    ToastificationContent,
    SearchWithAutoCompleteVue,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    records: {
      required: true,
      type: [Array, Function],
    },
    columns: {
      required: true,
      type: Array
    },
    tbodyRowClass: {
      type: Function,
      default: () => () => []
    },
    currentPageNumber: {
      type: Number,
      default: 0
    },
    totalRecords: {
      type: Number,
      default: 0
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    searchFilter: {
      type: String,
      required: false,
      default: ""
    },
    tableClass: {
      type: String,
      default: ""
    },
    selectionMode: {
      type: String,
      default: "single",
      validator: (value) => ['multi', 'single', 'range'].includes(value.toString())
    },
    useAutoCompleteSearch: {
      type: Boolean,
      default: false
    },
    searchType: {
      type: String,
      required: false,
      default: "users"
    },
    showExportButton: {
      type: Boolean,
      default: false,
      required: false
    },
    showImportButton: {
      type: Boolean,
      default: false,
      required: false
    },
    showFilterByDefault: {
      type: Boolean,
      default: true,
      required: false
    },
    importType: {
      type: String,
      default: "",
      required: false
    },
  },
  data() {
    return {
      search: "",
      showFilter: true,
      isImportFileActive: false,
      currentPage: this.currentPageNumber,
      selectedRows: []
    }
  },
  computed: {
    recordsPerPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
    },
    pageMeta(){
      const itemsCount = this.records.length || 0;
      return {
        from: this.recordsPerPage * (this.currentPageNumber - 1) + (itemsCount ? 1 : 0),
        to: this.recordsPerPage * (this.currentPageNumber - 1) + itemsCount,
        of: this.totalRecords,
      }
    },
    hasFilter() {
      return Object.keys(this.$scopedSlots).includes("filters");
    },
    hasActionButton() {
      return Object.keys(this.$scopedSlots).includes("action_button");
    },
    hasLegend() {
       return Object.keys(this.$scopedSlots).includes("legend");
    },
    filteredRecords() {
      return (this.records || []).filter(this.canView);
    }
  },
  watch: {
    currentPage: {
      handler(update){
        if (update !== this.currentPageNumber){
          this.$emit("update:currentPageNumber", update);
        }
      }, 
      immediate: false
    },
    recordsPerPage: {
      handler(update){
        if (update !== this.recordsPerPageNumber){
          this.$emit("update:recordsPerPageNumber", update);
        }
      }, 
      immediate: false
    },
    selectedRows: {
      handler(selectedRows){
        this.$emit("selected-rows", selectedRows)
      }, 
      deep: true,
      immediate: false
    }
  },
  created() {
    this.showFilter = this.showFilterByDefault;
  },
  destroyed(){
    this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, 0);
  },
  methods: {
    onTbRowClicked(rowItem){
      this.$emit("row-clicked", rowItem)
    },
    onRowsSelected(selectedRows){
      this.selectedRows = selectedRows;
    },
    onCheckOrUncheckAllRecords(checked){
      if (checked){
        this.$refs.listTable.selectAllRows()
      } else {
        this.$refs.listTable.clearSelected();
      }
    },
    onCheckOrUncheckRow(checked, rowIndex){
      if (checked){
        if (this.$refs.listTable.isRowSelected(rowIndex)){
          this.$refs.listTable.unselectRow(rowIndex)
        } else {
          this.$refs.listTable.selectRow(rowIndex)
        }
      } else if (!checked && this.$refs.listTable.isRowSelected(rowIndex)){
        this.$refs.listTable.unselectRow(rowIndex)
      }
    },
    onToggleFilter() {
      this.showFilter = !this.showFilter
    },
    onTriggerSelectImportLoanRecordsCsvFile(){
      this.isImportFileActive = true;
    },
    onImportUserRecordsCSVFileSelected(file){
      if (!file || !get(file, 'name').includes('.csv')) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: "Invalid file selected"
          },
        });
        return false;
      }

      this.$emit("import", file)
      this.isImportFileActive = false
      return true;
    },
  },
}
</script>

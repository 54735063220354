<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        :records="transactions" 
        :columns="tableColumns"
        :total-records="totalRecords"
        :search-filter.sync="searchFilter"
        :current-page-number.sync="currentPage"
        :show-export-button="true"
        :show-search="true"
        @export="onExportTransactions"
      >
        <template #filters>
          <b-row>
            <b-col cols="12" md="3">
              <b-form-datepicker
                v-model="dateFrom"
                :close-button="true"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="From"
                class="mr-2"
              />
            </b-col>

            <b-col cols="12" md="3" class="mb-md-0 mb-2">
              <b-form-datepicker
                v-model="dateTo"
                placeholder="To"
                reset-button
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :min="dateFrom"
              />
            </b-col>
          </b-row>
        </template>

        <template #cell(offering_type)="data">
          <p class="mb-2">
            {{ data.item.offering_type.title }}
          </p>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(download)="data">
          <b-button variant="primary" :disabled="loading" @click="onDownloadReceipt(data.item.id)">
            Print Receipt
          </b-button>
        </template>

        <template #cell(meta)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(getValueFromSource(data.item, 'meta.church_approval'))}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(getValueFromSource(data.item, 'meta.church_approval')) }}
          </b-badge>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BMedia, BAvatar, BButton, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BInputGroup, BInputGroupAppend, BFormInput, BFormDatepicker
} from 'bootstrap-vue'

import { get, debounce } from "lodash"

// eslint-disable-next-line import/no-extraneous-dependencies
import printJS from 'print-js'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FileDownload from "js-file-download";
import ListTable from "@/@core/components/ListTable/ListTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { base64toBlob } from "@core/utils/utils";

const watchHandler = {
  handler(){
    this.debouncedFetchData()
  }, 
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1
    this.debouncedFetchData()
  }, 
  immediate: false
} 

export default {
  components: {
    ListTable,

    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BFormInput,
    BInputGroup, 
    BFormDatepicker,
    BInputGroupAppend, 

    vSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      loading: false,

      showFilter: false,
      showListTable: false,
      
      searchFilter: null,
      dateFrom: null,
      dateTo: null,

      transactions: [],

      tableColumns: [
        { key: 'reference', sortable: false },
        { key: 'church_level', sortable: false, formatter: val => (val ? this.formatChurchLevel(val) : 'N/A') },
        { key: 'amount', sortable: false, formatter: val => `GH₵ ${this.formatMoney(val)}` },
        { key: 'offering_type', sortable: false, label: 'Offering' },
        { key: 'status', sortable: false, label: 'Status' },
        { key: 'download', sortable: false, label: 'Receipt' },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${this.formatDate(val)}`, },
      ],
      debouncedFetchData: () => {}
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    dataMeta(){
      const localItemsCount = this.users.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalRecords,
      }
    }
  },
  watch: {
    perPage: {
      handler(){
        this.currentPage = 1;
        this.debouncedFetchData()
      }, 
      immediate: false
    },
    currentPage: watchHandler,
    searchFilter: watchHandlerRequiresPageReset,
    dateFrom: watchHandlerRequiresPageReset,
    dateTo: watchHandlerRequiresPageReset,
  },
  created(){
    this.debouncedFetchData = debounce(this.fetchAllData, 500);

    const { 
      page = 1, 
      search = "", 
      from = "",
      to = "",
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.dateFrom = from;
    this.dateTo = to;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.searchFilter,
          from: this.dateFrom,
          to: this.dateTo
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().memberService.fetchTransactions(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;
        this.transactions = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportTransactions(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export data based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          search: this.searchFilter,
          from: this.dateFrom,
          to: this.dateTo
        }

        const response = await this.useJwt().memberService.exportTransactions(query);
        FileDownload(response.data, "my-transactions.csv");
      } catch (error) {
        // const error_message = get(error, "response.statusText") || error.message;
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'There is no data to export'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    async onDownloadReceipt(transaction_id) {
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Print?',
          text: "Do you want to print receipt for this transaction?",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const response = await this.useJwt().memberService.downloadOfferingReceipt(transaction_id);
        const { pdf } = response.data.data;

        this.printPDF(pdf)
        // FileDownload(response.data, "my-transactions.csv");
      } catch (error) {
        // const error_message = get(error, "response.statusText") || error.message;
        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Unable to print receipt. Try again later.'
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    printPDF(content) {
      const url = URL.createObjectURL(base64toBlob(content));

      printJS({
        printable: url,
        type: "pdf"
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .loan-list-tb-row {
    cursor: pointer !important;
  }
</style>
